import { Routes } from '@angular/router';

// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'costo',
                loadChildren: './costo/costo.module#CostoModule'
            },
            {
                path: 'investimento',
                loadChildren: './investimento/investimento.module#InvestimentoModule'
            },
            {
                path: 'fornitori',
                loadChildren: './fornitore/fornitore.module#CustomerModule'
            }
        ]
    }
];

